.AllServices{
    @media screen and (max-width: 1524px) {
    font-size: 120% !important;
  }
}

@media screen and (max-width: 1524px) {
    .AllViewAll {
      display: flex;
      align-items: flex-end;
      margin-top: 10px;
      margin-left: -40px;
    }
  }
 
  @media screen and (max-width: 1524px) {
    .AllViewAll {
      display: flex;
      align-items: flex-end;
      margin-top: 10px;
      margin-left: -40px;
    }
  }
 


  @media screen and (max-width: 1524px) {
    .SerivceHeader {
      display: flex;
      align-items: flex-start;
      margin-top: 10px;
      margin-left: -40px;
    }
  }
 