
  /* Default styles for the container */
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 115vh; /* Change to viewport height for responsiveness */
    margin-top: 50px;
  }

  /* Media query for screens below 1400px */
  @media screen and (max-width: 1500px) {
    .flex-container {
      
   width: fit-content;
    }
  }

  /* Media query for screens below 800px */
  @media screen and (max-width: 800px) {
    .flex-container {
      /* Change to column layout */
      flex-direction: column;
      align-items: center;
      
    }
  }
