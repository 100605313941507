.about-us-image {
    width: 100%;
    height: auto;
    max-height: 535px; /* Adjust this value as needed */
    object-fit: cover;
  }
  
  /* Style for the text container */
  .about-us-container {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 1rem; /* Adjust padding as needed */
    border-radius: 0.5rem; /* Adjust border radius as needed */
    color: white;
  }
  
  /* Media queries for responsiveness */
  @media (min-width: 640px) {
    .about-us-container {
      padding: 2rem; /* Adjust padding for medium screens */
    }
  }
  
  @media (min-width: 768px) {
    .about-us-container {
      padding: 4rem; /* Adjust padding for large screens */
    }
  }
  
  @media (min-width: 1024px) {
    .about-us-container {
      padding: 8rem; /* Adjust padding for extra-large screens */
    }
  }


.bg-black-opacity-20 {
    background-color: black;
    opacity: 0.2;
  }
  
  .bg-black-opacity-40 {
    background-color: black;
    opacity: 0.4;
  }
  
  .text-center {
    text-align: center;
  }
  
  /* Responsive styles */

  @media (max-width: 480px) {
    .text-xs {
      font-size: 6px;
    }
  }
  @media (min-width: 480px) and (max-width: 520px) {
    .text-xs {
        font-size: 8px;
      }
  }

  @media (max-width: 481px) and (max-width: 640px){
    .text-xs {
      font-size: 6px;
    }
  }
  
  @media (min-width: 641px) and (max-width: 1024px) {
    .text-sm {
      font-size: 14px;
    }
  }
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    .text-base {
      font-size: 16px;
    }
  }
  
  @media (min-width: 1281px) and (max-width: 1536px) {
    .text-lg {
      font-size: 18px;
    }
  }
  
  @media (min-width: 1537px) {
    .text-xl {
      font-size: 20px;
    }
  }

/* External CSS file (styles.css) */
/* Responsive styles */
/* @media (max-width: 767px) {
  .custom-container {
    flex-direction: column;
  }

  .custom-button-container {
    width: 100%;
    margin-top: 1rem;
    margin-right: 0;
  }

  .custom-image-container {
    width: 100%;
    margin-top: 1rem;
    margin-left: 0;
  }

  .custom-button {
    width: 100%;
  }

  .custom-description {
    width: 100%;
  }
} */

/* Position image beside buttons for larger screens */
/* @media (min-width: 1920px) {
  .custom-button-container {
    width: 50%;
  }

  .custom-image-container {
    width: 50%;
    margin-left: 1rem;
  }
} */

@media (max-width: 767px) {
  .flex-container {
    flex-direction: column;
  }

  .card {
    width: 300px;
    margin-bottom: 0px;
  }
}

.last-container {
  /* Default styles */
}

/* Media query for tablets (768px and up) */
@media (min-width: 300px)  and (max-width: 766px){
  .last-container {
    padding-top: 150px; /* Adjust as per your requirements */
  }
}

@media (min-width: 300px)  and (min-width: 767px) {
  .last-container {
    padding-top:auto; /* Adjust as per your requirements */
  }
}

/* Media query for laptops/desktops (1024px and up) */
@media (min-width: 1024px) {
  .last-container {
    padding-top: 40px; /* Adjust as per your requirements */
  }
}

/* Media query for larger screens (1200px and up) */
@media (min-width: 1200px) {
  .last-container {
    padding-top: 60px; /* Adjust as per your requirements */
  }
}



@media (min-width: 1280px) and (max-width: 1800px) {
  .first-container {
    padding-left: 70px;
  }
  .second-container {
    padding-left: 85px;
    padding-right: 100px;
  }
  .image-2{
    width: 750px;
    height: 600px;
  }
  .image-3 {
    padding-left: 0%;
  }
  .comprehensive-container {
    font-size: 10px;
  }
}

@media (min-width: 1535px) and (max-width: 1800px) {
  .box-text{
    font-size: 20px;
  }

  .title-text {
    font-size: 10px;
  }
  
}

@media (min-width: 1800px) and (max-width: 2766px) {
  .padding-gg {
    padding-left: 200px;
    padding-right: 200px;
  }

  .box-text {
    font-size: 30px;
  }
  .box-text-inner {
    font-size: 20px;
  }
  

  .box-text1 {
    margin-bottom: 15px;
  }
}