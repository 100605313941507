/* LoginPage.css */

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@keyframes bounceIn {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  50% {
    transform: translateY(20px);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.form-container {
  animation: slideInFromLeft 1s ease-out;
}

.form-title {
  animation: fadeIn 1s ease-in-out;
}

.form-input {
  animation: fadeIn 1s ease-in-out;
}

.animated-button {
  animation: bounce 1s infinite;
}

.google-signin {
  animation: scaleIn 1s ease-in-out;
}

.logo {
  animation: bounceIn 1s ease-in-out;
}
