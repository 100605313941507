
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

*{
  font-family: 'Poppins', sans-serif;
}

body, html{
  overflow-x: hidden !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-on-time-color{
  background-color: #C6FFC1;
}

.bg-pay-now{
  background-color: #10C500;
}

/*
.mapboxgl-directions-destination,
.mapboxgl-directions-origin,.mapboxgl-directions-control{
  display: none !important;
}
*/

.suggestions {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.suggestions li {
  padding: 8px 16px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f5f5f5;
  cursor: pointer; 
}