@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
/* .slider {
  width: 85%;
  height: 400px;
  margin: 0 auto;
  overflow: hidden;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin-left: 135px; 

} 
.slide,
.slide img {
  width: 100%;
  height: 350px;
  border-radius: 10px; 
}

.slick-center .slide {
  border-radius: 20px; 
}

.slide {
  transform: scale(0.77);
  transition: .5s;
  filter: blur(1px);
  box-shadow: 0 0 5px 3px #ccc; 
}

.slide-active {
  filter: blur(0);
  opacity: 1;
  transform: scale(1.001);
  border-radius: 20px;
  box-shadow: 0 0 10px 5px #ccc;
}



.arrow {
  font-size: 30px;
  padding: 5px 15px;
  border-radius: 10px;
  box-shadow: 0 0 5px 3px #ccc;
  display: flex;
  width: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); 
  z-index: 100;
  cursor: pointer;
}

.arrow-right {
  right: 20px;
}

.arrow-left {
  left: 20px;
}

.dot {
  margin-top: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0 0 5px 3px #ccc;
}

.dot-active {
  background: #121227;
  box-shadow: 0 0 15px 10px #ccc;
} */

/* @media screen and (max-width: 768px) {
  .container {
    align-items: flex-start;
  }
  .slider {
    width: 100%;
  }

}

@media screen and (max-width: 1524px) {
  
  .text-overlay {
    font-size: 20px !important;
  
    
  }
  .descriptionStyle{
    font-size: 9px !important;
  }
  .cartButton{
    font-size: 30px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;  
    margin-bottom: auto;
   margin-left: -40%;
  }
} */

/* @media screen and (max-width: 639px) {
  .carousel-container{
    padding: 0 20px;
  }

  .current-slide{
    transform: scale(1.15) !important;
  }
}

@media screen and (max-width: 475px) {
  .carousel-container{
    padding: 0;
  }

  .slick-arrow{
    display: none !important;
  }

  .current-slide{
    transform: scale(1.25) !important;
  }
} */

@media screen and (max-width: 375px) {
  .slick-dots li{
    margin: 0;
    padding: 0;
    width: 12px !important;
    height: 12px !important;
  }

  /* .current-slide{
    transform: scale(1.5) !important;
  } */
}