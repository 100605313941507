.responsive-container {
    max-height: calc(100vh - 200px); /* Default height */
  }
  
  @media screen and (max-width: 768px) {
    .responsive-container {
      max-height: calc(100vh - 280px);
     
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .responsive-container {
      max-height: calc(100vh - 240px);
    }
  }
  
  @media screen and (min-width: 1025px) {
    .responsive-container {
      max-height: calc(100vh - 280px);
    }
  }
  .responsive-container2 {
    max-height: calc(100vh - 320px); /* Default height */
  }
  
  @media screen and (max-width: 768px) {
    .responsive-container2 {
      max-height: calc(100vh - 200px);
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .responsive-container2 {
      max-height: calc(100vh - 260px);
    }
  }
  
  @media screen and (min-width: 1025px) {
    .responsive-container2 {
      max-height: calc(100vh - 280px);
    }
  }
  .responsive{
     max-height: calc(100vh - 260px)
  }
  .responsive2{
    max-height: calc(100vh - 320px)
  }
    @media screen and (max-width: 768px) {
        .responsive2 {
          max-height: calc(100vh - 260px);
        }
      }
 