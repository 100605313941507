@media screen and (max-width: 550px){
    .mech-time{
        display: block;
    }
    .mech-time p{
        margin-top: 10px;
    }
}

@media screen and (max-width: 450px){
    .service-billing{
        display:flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 640px){
    .progress{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .progress-item{
        margin: 50px 0;
        display: flex;
        flex-direction: row;
    }
    .progress-item p{
        width:150px;
    }
}