
.swiper {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: block;
  width: 280px;
  height: 310px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 50%;
}

.swiper-button-prev, .swiper-button-next {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px; /* Adjust the font size for the icon */
  color: #ffffff; /* Default color for the icon */
  padding: 18px;
  border-radius: 5px;
}

/* Style for the grey box inside the icon */
.swiper-button-prev::before, .swiper-button-next::before {
  content: '';
  display: block;
  width: 48px; /* Adjust the width to match your icon size */
  height: 48px; /* Adjust the height to match your icon size */
  background-color: #aaa5a5; /* Grey color */
  position: absolute;
  border-radius: 3px; /* Adjust border radius as needed */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; /* Ensure the grey box is behind the icon */
}

/* Style for the icon itself */
.swiper-button-prev::after, .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold; 
}

.text-xl:not(:hover) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
